import PropTypes from 'prop-types'
import React from 'react'
import MyTimeline from './MyTimeline'
import Projects from './Projects'

import pic01 from '../images/pic01.jpg'
import pic02 from '../images/pic02.jpg'
import pic03 from '../images/pic03.jpg'

class Main extends React.Component {
  render() {
    let close = (
      <div
        className="close"
        onClick={() => {
          this.props.onCloseArticle()
        }}
      ></div>
    )

    return (
      <div
        ref={this.props.setWrapperRef}
        id="main"
        style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}
      >
        <article
          id="intro"
          className={`${this.props.article === 'intro' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Intro</h2>
          {/* <span className="image main">
            <img src={pic01} alt="" />
          </span> */}
          <p>
            Hi, I am a 27 year old living in Bengaluru, Karnataka, India. I hail
            from Odisha. A software developer for ~6 years now.
          </p>
          <p>
            Big time football fan. Glory Glory Manchester United. Football isn't
            just a passion, it is my way of life. Paul Scholes is my favourite
            player of all time.
          </p>
          <p>
            Love travelling, it helps me expand my perspective, relaxes and
            rejuvenates me. Travelling teaches me about different cultures and
            IMHO is the best way to experience something unfamiliar. To
            this date I have visited 12 states in India &amp; the UAE (Kanafeh
            is a dish I wont be forgeting anytime soon). Covid19 enforced
            travel-pause is active now.
          </p>
          <p>
            If I am not travelling, Gaming is what keeps me sane. PC and Mobile
            gaming were my goto untill recently. PS4 all the way now. FIFA
            causes violence. \m/\m/
          </p>
          <p>By the way, check out my work.</p>
          <p>
            P.S I am open to pro bono developmental work or volunteering in a
            technical aspect for a non profit organisation.
          </p>
          <p>
            P.P.S Thanks{' '}
            <a href="https://github.com/ChangoMan" target="_blank">
              @ChangoMan
            </a>
            {" "}for this beautiful and elegant{' '}
            <a
              href="https://github.com/codebushi/gatsby-starter-dimension"
              target="_blank"
            >
              gatsby-starter template.
            </a>
          </p>
          {close}
        </article>

        <article
          id="work"
          className={`${this.props.article === 'work' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Work - Education</h2>
          {/* <span className="image main">
            <img src={pic02} alt="" />
          </span> */}
          <MyTimeline />
          {close}
        </article>

        <article
          id="about"
          className={`${this.props.article === 'projects' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Projects</h2>
          {/* <span className="image main">
            <img src={pic03} alt="" />
          </span> */}
          <Projects />
          {close}
        </article>

        <article
          id="contact"
          className={`${this.props.article === 'contact' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Contact</h2>
          <form
            netlify
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            name="contact"
            method="post"
            action="#"
          >
            <input type="hidden" name="form-name" value="contact" />
            <div className="field half first">
              <label htmlFor="name">Name</label>
              <input type="text" name="name" id="name" />
            </div>
            <div className="field half">
              <label htmlFor="email">Email</label>
              <input type="text" name="email" id="email" />
            </div>
            <div className="field">
              <label htmlFor="message">Message</label>
              <textarea name="message" id="message" rows="4"></textarea>
            </div>
            <ul className="actions">
              <li>
                <input type="submit" value="Send Message" className="special" />
              </li>
              <li>
                <input type="reset" value="Reset" />
              </li>
            </ul>
          </form>
          {close}
        </article>
      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main
